import React from "react"
import { ThemeProvider } from "styled-components"

import { Header } from "src/components/Header"
import { Footer } from "src/components/Footer"
import { theme } from "src/styles/theme"
import { GlobalStyle } from "src/styles/global"

export const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyle></GlobalStyle>
        <Header></Header>
        <main>{children}</main>
        <Footer></Footer>
      </>
    </ThemeProvider>
  )
}
