import React from "react"
import styled from "styled-components"
import is from "styled-is"

export const Container = ({ children, article }) => {
  return <Wrap article={article}>{children}</Wrap>
}

const Wrap = styled.div`
  width: 100%;
  max-width: 1172px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;

  ${is("article")`
 max-width: 980px
  `};
`
