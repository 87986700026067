import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Helmet from "react-helmet"

import logo from "src/svg/logo.svg"
import { Container } from "src/components/Container"
import { MainNavigation } from "src/components/MainNavigation"

export const Header = () => {
  return (
    <Wrap>
      <Helmet>
        <script
          src="//code.jivosite.com/widget.js"
          data-jv-id="qHI4i9JEqB"
          async
        ></script>
      </Helmet>
      <Container>
        <Inner>
          <Logo to="/">
            <img src={logo} alt="logo"></img>
            <h3>HANDMADE GIFTS</h3>
          </Logo>

          <MainNavigation></MainNavigation>
        </Inner>
      </Container>
    </Wrap>
  )
}

const Wrap = styled.header`
  margin-top: 24px;
  margin-bottom: 24px;
`

const Logo = styled(Link)`
  display: flex;
  align-items: center;

  img {
    max-height: 40px;
  }

  h3 {
    color: #091e3f;
    margin: 0;
    margin-left: 8px;
    font-weight: 900;
    font-size: 16px;
  }
`

const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
