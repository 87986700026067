import circeRegular from "src/fonts/CirceRegular.woff2"
import circeBold from "src/fonts/CirceBold.woff2"
import circeExtraBold from "src/fonts/CirceExtraBold.woff2"

export const injectFonts = () => `
@font-face {
    font-family: 'Circe';
    src: url(${circeRegular});
    font-weight: normal;
    font-style: normal;
 }

 @font-face {
    font-family: 'Circe';
    src: url(${circeBold});
    font-weight: bold;
    font-style: normal;
 }

 @font-face {
    font-family: 'Circe';
    src: url(${circeExtraBold});
    font-weight: 900;
    font-style: normal;
 }
`
