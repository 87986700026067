import { createGlobalStyle } from "styled-components"
import { modernNormalize } from "styled-modern-normalize"

import { injectFonts } from "src/styles/fonts"
import arrowRightIcon from "src/svg/arrow-right.svg"
import arrowLeftIcon from "src/svg/arrow-left.svg"

export const GlobalStyle = createGlobalStyle`
${modernNormalize}
html {
    font-size: 16px;
}

body {
  ${injectFonts};
  background-color: #F2F6FA;
  color: #161616;
  font-family: 'Circe';
  font-style: normal;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;

  .swiper-button-next {
    background-image: url(${arrowRightIcon});
  }

  .swiper-button-prev {
    background-image: url(${arrowLeftIcon});
  }
}

a {
  text-decoration: none;
  color: #161616;
}

button {
  &:hover {
    cursor: pointer;
  }
}
`
