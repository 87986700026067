import React, { useState, useRef, useEffect } from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import styled from "styled-components"
import is from "styled-is"

import { Container } from "src/components/Container"
import menuIcon from "src/svg/menu.svg"

const localLinks = [
  { name: "Сотрудничество", link: "/cooperation" },
  { name: "Контакты", link: "/contact" },
]

export const MainNavigation = () => {
  const [isOverlayVisible, setOverlayVisibility] = useState(false)
  const overlayRef = useRef(null)

  const handleClickOutside = e => {
    if (overlayRef.current && !overlayRef.current.contains(e.target)) {
      setOverlayVisibility(false)
    }
  }

  const handleAllCategoriesClick = () => setOverlayVisibility(!isOverlayVisible)

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.document.addEventListener("mousedown", handleClickOutside)
    }
    return () => {
      window.document.removeEventListener("mousedown", handleClickOutside)
    }
  })

  return (
    <StaticQuery
      query={query}
      render={({
        allDatoCmsSection: { nodes: sections },
        allDatoCmsCategory: { nodes: categories },
      }) => (
        <>
          <Navigation isOverlayVisible={isOverlayVisible}>
            <ul>
              <li>
                <button onClick={handleAllCategoriesClick}>
                  Все категории ↓
                </button>
              </li>
              {sections.map(({ title, id, slug }) => (
                <li key={id}>
                  <Link to={`/section/${slug}`}>{title}</Link>
                </li>
              ))}
              {localLinks.map(({ name, link }) => (
                <li key={name}>
                  <Link to={link}>{name}</Link>
                </li>
              ))}
            </ul>

            {isOverlayVisible && (
              <Overlay ref={overlayRef}>
                <Container>
                  <Inner>
                    {categories.map(({ name, id, slug, subcategory }) => (
                      <Category key={id}>
                        <CategoryHeading to={`/category/${slug}`}>
                          {name}
                        </CategoryHeading>

                        {subcategory.length > 0 &&
                          subcategory.map(({ shortName, id, slug }) => (
                            <Subcategory key={id} to={`/subcategory/${slug}`}>
                              {shortName}
                            </Subcategory>
                          ))}
                      </Category>
                    ))}
                  </Inner>
                </Container>
              </Overlay>
            )}
          </Navigation>
          <MobileNavigation
            sections={sections}
            categories={categories}
            handleAllCategoriesClick={handleAllCategoriesClick}
          ></MobileNavigation>
        </>
      )}
    ></StaticQuery>
  )
}

const MobileNavigation = ({ sections, handleAllCategoriesClick }) => {
  const [isMobileOverlayVisible, setMobileOverlayVisibility] = useState(false)

  const handleMobileMenuClick = () =>
    setMobileOverlayVisibility(!isMobileOverlayVisible)

  return (
    <>
      <MenuButton onClick={handleMobileMenuClick}>
        <img src={menuIcon} alt=""></img>
      </MenuButton>
      {isMobileOverlayVisible && (
        <MobileOverlay>
          <nav>
            <ul>
              <button onClick={handleAllCategoriesClick}>
                Все категории ↓
              </button>

              {sections.map(({ title, id, slug }) => (
                <li key={id}>
                  <Link to={`/section/${slug}`}>{title}</Link>
                </li>
              ))}

              {localLinks.map(({ name, link }) => (
                <li key={name}>
                  <Link to={link}>{name}</Link>
                </li>
              ))}
            </ul>
          </nav>
        </MobileOverlay>
      )}
    </>
  )
}

const query = graphql`
  query {
    allDatoCmsCategory {
      nodes {
        name
        slug
        id
        subcategory {
          shortName
          id
          slug
        }
      }
    }

    allDatoCmsSection {
      nodes {
        title
        slug
        id
      }
    }
  }
`

const Navigation = styled.nav`
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  li {
    display: inline-block;
    margin-right: 32px;

    &:last-child {
      margin-right: 0;
    }
  }

  button {
    border: 0;
    padding: 0;
    background-color: transparent;
    font-size: 16px;
    outline: none;
  }

  a,
  button {
    transition: all 0.2s;
    color: ${({ theme }) => theme.colors.contrast};

    &:hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  ${is("isOverlayVisible")`
  button {
    color: ${({ theme }) => theme.colors.primary};
  }
  `};

  @media (max-width: ${({ theme }) => theme.breakpoints.l}) {
    ul {
      display: none;
    }
  }
`

const Overlay = styled.aside`
  width: 100%;
  position: absolute;
  top: 88px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
`

const Inner = styled.div`
  background-color: #fff;

  padding: 32px;

  min-height: 50vh;
  border-radius: 6px;
  box-shadow: 0 5px 20px rgba(15, 41, 48, 0.03);

  display: flex;
  flex-wrap: wrap;
`

const Category = styled.div`
  flex-basis: calc(100% / 3);

  margin-bottom: 32px;

  @media (max-width: ${({ theme }) => theme.breakpoints.l}) {
    flex-basis: 50%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    flex-basis: 100%;
  }
`

const CategoryHeading = styled(Link)`
  transition: all 0.2s;
  display: inline-block;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 16px;

  color: ${({ theme }) => theme.colors.contrast};

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`

const Subcategory = styled(Link)`
  transition: all 0.2s;

  display: block;
  margin-bottom: 8px;

  color: ${({ theme }) => theme.colors.contrast};

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }

  &::first-letter {
    text-transform: uppercase;
  }
`

const MenuButton = styled.button`
  padding: 0;
  border: 0;
  background-color: transparent;
  outline: none;
  display: none;
  position: relative;
  z-index: 20;

  @media (max-width: ${({ theme }) => theme.breakpoints.l}) {
    display: block;
  }
`

const MobileOverlay = styled.aside`
  width: 100%;
  height: 100%;
  background-color: #f2f6fa;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;

  nav {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0;
        }

        a {
          font-size: 24px;
        }
      }
    }

    button {
      padding: 0;
      margin-bottom: 16px;
      border: 0;
      background-color: transparent;
      font-size: 24px;
    }
  }
`
