const breakpoints = ["320px", "768px", "992px", "1200px"]

breakpoints.s = breakpoints[0]
breakpoints.m = breakpoints[1]
breakpoints.l = breakpoints[2]
breakpoints.xl = breakpoints[3]

const colors = {
  primary: "#8C74C9",
  contrast: "#3b3b3b",
}

export const theme = {
  breakpoints,
  colors,
}
