import React from "react"
import { Helmet as ReactHelmet } from "react-helmet"

export const Helmet = ({ title }) => {
  return (
    <ReactHelmet defer={false}>
      <title>Handmade gifts {title && ` | ${title}`}</title>
    </ReactHelmet>
  )
}
