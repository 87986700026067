import React, { useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import addToMailchimp from "gatsby-plugin-mailchimp"

import { Container } from "src/components/Container"
import vkIcon from "src/svg/vk.svg"
import instaIcon from "src/svg/insta.svg"

export const Footer = () => {
  const [email, setEmail] = useState("")
  const [formSubmitted, setFormSubmitingStatus] = useState(false)

  const handleEmailInput = e => {
    setEmail(e.currentTarget.value)
  }

  const handleFormSubmit = async e => {
    e.preventDefault()
    await addToMailchimp(email)
    setFormSubmitingStatus(true)
    setEmail("")
  }

  return (
    <Wrap>
      <Container>
        <Inner>
          <h2>
            Будь в курсе последних событий
            <br></br>и акционных предложений
          </h2>
          <p>
            Подпишись на нашу новостную рассылку.<br></br>Одно сообщение в
            неделю и никакого спама, честно!
          </p>
        </Inner>
        <Subscribe onSubmit={handleFormSubmit}>
          <div>
            <input
              type="email"
              placeholder="Введите Ваш email"
              value={email}
              onChange={handleEmailInput}
            ></input>
            {!formSubmitted && <button type="submit">Подписаться</button>}
            {formSubmitted && <Success>Успешно!</Success>}
          </div>
        </Subscribe>

        <Navigation>
          <ul>
            <li>
              <Link to="/">
                <img src={vkIcon} alt=""></img>
              </Link>
            </li>

            <li>
              <Link to="/">
                <img src={instaIcon} alt=""></img>
              </Link>
            </li>
          </ul>
        </Navigation>
      </Container>
    </Wrap>
  )
}

const Wrap = styled.footer`
  background-color: #23272f;
  padding-top: 88px;
  padding-bottom: 88px;
`

const Inner = styled.div`
  text-align: center;

  h2 {
    color: #fff;
    font-size: 7.2vmin;
    line-height: 120%;
    margin: 0;
  }

  p {
    color: #cccccc;
    font-size: 4vmin;
    line-height: 120%;
    padding-top: 24px;
    padding-bottom: 24px;
  }
`

const Subscribe = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;

  input {
    background-color: #1c1f26;
    border-radius: 6px;
    border: none;

    padding: 16px 24px;

    color: #fff;
    font-size: 18px;
    font-weight: bold;

    margin-right: 16px;

    @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
      width: 100%;
    }
  }

  button {
    transition: all 0.3s ease;
    background-color: ${({ theme }) => theme.colors.primary};
    border-radius: 6px;
    border: none;

    padding: 16px 24px;
    color: #fff;
    font-weight: bold;
    font-size: 18px;

    @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
      margin-top: 24px;
      width: 100%;
    }
  }
`

const Navigation = styled.nav`
  margin-top: 64px;
  text-align: center;
  ul {
    padding: 0;
    margin: 0;

    li {
      display: inline-block;
      list-style: none;
      margin-right: 24px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
`

const Success = styled.div`
  display: inline-block;
  background-color: #62d2a2;
  padding: 16px 48px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  border-radius: 6px;
`
